@charset "UTF-8";
.app {
  scroll-behavior: smooth;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 32px;
  grid-row-gap: 64px;
  padding: 0 64px;
  /* 2x64 margin top,bottom= 128  */
  height: calc(100% - 128px);
  box-sizing: border-box;
  max-width: 1440px;
  margin: 0 auto;
  margin-bottom: 128px;
  vertical-align: middle;
}

/* Color styles */
.button {
  font-family: Nippo;
  position: relative;
  display: flex;
  padding: 13px 48px 15px 48px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
  text-align: center;
  border: none;
  outline: none;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  border-radius: 0;
  cursor: pointer;
  transition: all 0.66s cubic-bezier(0.22, 1, 0.36, 1);
}

.button.button--compact {
  padding: 10px 24px 12px 24px;
}

.button.button--pill {
  border-radius: 64px;
}

.button.button--rounded {
  border-radius: 8px;
}

.button.button--filled.button--default {
  color: rgb(255, 255, 255);
  background: rgb(34, 30, 31);
  text-align: left;
  justify-content: left;
  padding-inline: 30px;
  font-weight: 400;
  text-transform: uppercase;
  fill: rgb(255, 255, 255);
}

.button.button--filled.button--default.button--appended {
  justify-content: space-between;
}

.button.button--filled.button--gray {
  color: rgb(34, 30, 31);
  background: rgb(246, 247, 250);
  text-align: left;
  justify-content: left;
  padding-inline: 30px;
  font-weight: 400;
  text-transform: uppercase;
  fill: rgb(34, 30, 31);
}

.button.button--filled.button--gray.button--appended {
  justify-content: space-between;
}

.button.button--filled.button--primary {
  display: flex;
  width: 260px;
  height: 80px;
  padding: 15px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-bottom: 4px solid #B6AC9E;
  background: url("/src/resources/SpeedArrows.png") rgb(210, 203, 192);
  background-blend-mode: screen;
  background-size: 130px;
  color: rgb(20, 46, 58);
  font-family: Nippo;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  stroke-width: 2px;
  transition: all 0.66s cubic-bezier(0.22, 1, 0.36, 1);
}

.button.button--filled.button--primary:hover {
  background: url("/src/resources/SpeedArrows.png") rgb(20, 46, 58);
  background-blend-mode: color-dodge;
  background-size: 160px;
  color: rgb(255, 255, 255);
  fill: rgb(255, 255, 255);
  border-bottom: 4px solid #0D1F28;
  gap: 0px;
  transition: all 0.66s cubic-bezier(0.22, 1, 0.36, 1);
}

.button.button--filled.button--default:hover {
  background: rgb(20, 46, 58);
  color: rgb(255, 255, 255);
  fill: rgb(255, 255, 255);
}

.button.button--filled.button--gray:hover {
  background: rgb(20, 46, 58);
  color: rgb(255, 255, 255);
  fill: rgb(255, 255, 255);
}

.button.button--ghost {
  display: flex;
  padding: 13px 48px 15px 48px;
  justify-content: center;
  align-items: flex-end;
  gap: 8px;
}

.button.button--ghost.button--default {
  color: rgb(34, 30, 31);
  background: none;
  text-align: center;
  font-family: Nippo;
  font-size: 16px;
  font-style: normal;
  font-weight: 378;
  line-height: normal;
}

.button--selected {
  border-bottom: 4px solid rgb(20, 46, 58);
}

.button__append {
  display: none;
}

.button--appended .button__append {
  display: inline-flex;
  width: 24px;
  height: 24px;
}

.button--ghost:hover {
  cursor: pointer;
  transform: translateY(-5px);
  transition: all 0.66s cubic-bezier(0.22, 1, 0.36, 1);
}

/* Color styles */
.hero__container {
  grid-column: 1/span 12;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 128px;
  max-width: 1280px;
  max-height: 876px;
  box-shadow: 0px 4.26158px 2.21381px 0px rgba(0, 0, 0, 0.05), 0px 10.24116px 5.32008px 0px rgba(0, 0, 0, 0.08), 0px 19.28319px 10.01724px 0px rgba(0, 0, 0, 0.09), 0px 34.39791px 17.86905px 0px rgba(0, 0, 0, 0.11), 0px 64.33752px 33.42209px 0px rgba(0, 0, 0, 0.14), 0px 154px 80px 0px rgba(0, 0, 0, 0.19);
}

.hero__container .hero__container--right {
  display: flex;
  flex-direction: column;
  gap: 0;
}

.hero__container .hero__container--right img:nth-child(2) {
  max-height: 140px;
}

.hero__container .hero__container--right img,
.hero__container .hero__container--right .pfm_video__container,
.hero__container .hero__container--right .pfm_video {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.hero__container::after {
  top: 0;
  left: 0;
  content: "";
  position: absolute;
  width: 100%;
  background: url("/src/resources/HERO_PATTERN.png") rgb(34, 30, 31);
  height: calc(100% - 186px);
  z-index: -1;
}

.hero__footer-container {
  display: flex;
  flex-direction: row;
  position: relative;
}

.hero__footer-container .hero__footer-content {
  width: 100%;
  background: rgb(255, 255, 255);
  overflow: hidden;
}

.hero__footer-content {
  display: flex;
  align-items: center;
  padding-left: 32px;
}

.heritage .heritage-text--strong {
  color: rgb(34, 30, 31);
  font-family: Nippo;
  font-size: 42px;
  font-style: normal;
  font-weight: 500;
  line-height: 103%;
}

.heritage .heritage-text--medium {
  color: rgb(98, 95, 96);
  font-family: Nippo;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 103%;
}

.heritage .heritage-text {
  color: rgb(34, 30, 31);
  font-family: Nippo;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 103%;
}

@media screen and (max-width: 1000px) {
  .hero__container {
    height: fit-content;
    max-height: unset;
    flex-direction: column;
  }
  .hero__container .hero__container--right {
    display: none;
  }
}
@media screen and (max-width: 730px) {
  .hero__footer-container {
    flex-direction: column;
  }
  .hero__footer-container .hero__footer-content {
    box-sizing: border-box;
    width: 100%;
    padding: 32px;
  }
}
/* Color styles */
.cta__wrapper .button {
  width: 100%;
  max-width: 450px;
}

.cta__wrapper .cta-title__container {
  display: flex;
  max-width: 656px;
  max-height: 402px;
  padding: 48px 64px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 48px;
  flex-shrink: 0;
  background-color: rgb(255, 255, 255);
  position: relative;
}

.cta__wrapper .cta-title__container::after {
  content: "";
  position: absolute;
  bottom: 50px;
  right: -48px;
  background: url("/src/resources/SpeedArrows.png");
  width: 274px;
  height: 174px;
  transform: rotate(90deg);
  opacity: 0.05;
}

.cta__wrapper .cta-title {
  color: rgb(34, 30, 31);
  font-family: Nippo;
  font-size: 82px;
  font-style: normal;
  font-weight: 500;
  line-height: 102px;
  transition: all 0.66s cubic-bezier(0.22, 1, 0.36, 1);
}

.cta__wrapper .cta__content-wrapper {
  display: flex;
  box-sizing: border-box;
  height: 335px;
  padding: 32px 64px 64px 64px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  flex-shrink: 0;
  background: url("/src/resources/CTA_BG.png") rgb(246, 247, 250);
}

.cta__wrapper .cta__content-wrapper .cta__paragraph {
  color: rgb(34, 30, 31);
  font-family: Nippo;
  font-size: 20px;
  font-style: normal;
  font-weight: 378;
  line-height: normal;
}

@media screen and (max-width: 1000px) {
  .cta__wrapper .cta-title__container {
    width: 100%;
    max-width: unset;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 730px) {
  .cta__wrapper .cta-title__container {
    padding: 32px;
    max-height: 330px;
  }
  .cta__wrapper .cta__content-wrapper {
    height: 220px;
    padding-left: 32px;
  }
  .cta__wrapper .cta-title {
    font-size: 56px;
    line-height: 86px;
    transition: all 0.66s cubic-bezier(0.22, 1, 0.36, 1);
  }
}
@media screen and (max-width: 730px) {
  .cta-title__container {
    background-color: rgb(34, 30, 31) !important;
  }
  .cta-title__container .cta-title {
    color: rgb(255, 255, 255);
  }
  .cta__wrapper .cta-title {
    font-size: 40px;
    line-height: 64px;
    font-weight: 500;
    transition: all 0.66s cubic-bezier(0.22, 1, 0.36, 1);
  }
  .hero__container::after {
    display: none;
  }
}
/* Color styles */
.header {
  display: flex;
  align-items: center;
  width: 100%;
  height: 80px;
}

.header__logotype {
  display: flex;
  align-items: baseline;
  gap: 16px;
}

/* Color styles */
.navbar {
  top: 0;
  width: 100%;
  position: fixed;
  display: flex;
  z-index: 25;
  justify-content: center;
  box-shadow: 0px -5px 0px 0px #DEDDDD inset;
  background: rgb(255, 255, 255);
}

.navbar .divider_container {
  bottom: 0;
  position: absolute;
  height: 3px;
}

.navbar:hover .divider_container {
  opacity: 0;
  transition: all 0.66s cubic-bezier(0.22, 1, 0.36, 1);
}

.navbar__content {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0px 0 4px 64px;
  transition: all 0.66s cubic-bezier(0.22, 1, 0.36, 1);
}

.navbar__buttons {
  display: flex;
  justify-content: center;
  align-items: baseline;
  gap: 16px;
}

@media (max-width: 940px) {
  .header {
    justify-content: center;
  }
  .navbar__content {
    flex-direction: column;
    padding-bottom: 0px;
    transition: all 0.66s cubic-bezier(0.22, 1, 0.36, 1);
  }
  .navbar__buttons {
    width: 100%;
    justify-content: space-between;
  }
}
@media (max-width: 620px) {
  .navbar__content {
    flex-direction: row;
    padding: 16px 24px;
    transition: all 0.66s cubic-bezier(0.22, 1, 0.36, 1);
  }
  .header {
    justify-content: flex-start;
  }
}
@media (max-width: 410px) {
  .hamburger__menu {
    display: none;
  }
  .header__logotype {
    gap: 6px;
  }
}
/* Color styles */
.hamburger__container {
  position: relative;
}

.hamburger {
  font-family: Nippo;
  display: none;
  cursor: pointer;
  color: rgb(255, 255, 255);
  padding: 12px 24px;
  background: rgb(34, 30, 31);
  border-radius: 4px;
  align-items: center;
  transition: all 0.66s cubic-bezier(0.22, 1, 0.36, 1);
}

.hamburger:hover {
  background: rgb(20, 46, 58);
  transition: all 0.66s cubic-bezier(0.22, 1, 0.36, 1);
}

.hamburger:hover .hamburger__lines-wrapper .line {
  background-color: rgb(255, 255, 255);
}

.hamburger__wrapper {
  display: flex;
  flex-direction: row-reverse;
  gap: 8px;
  align-items: center;
}

.hamburger__lines-wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.line {
  width: 18px;
  height: 2px;
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
}

.hamburger__menu {
  color: rgb(255, 255, 255);
  user-select: none;
  text-align: center;
  font-family: Nippo;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
}

.hamburger__dropdown {
  display: none;
  position: absolute;
  top: 50px;
  left: -167px;
  flex-direction: column;
  gap: 42px;
  background: rgb(246, 247, 250);
  padding: 24px;
  border-bottom: 5px solid #dedddd;
}

/* Add any additional styling needed for your navbar */
@media (max-width: 620px) {
  .hamburger {
    display: flex;
  }
  .hamburger__dropdown--active {
    display: flex;
  }
  .navbar__buttons {
    display: none;
  }
}
@media (max-width: 410px) {
  .hamburger {
    padding: 16px 16px;
  }
  .hamburger__dropdown {
    left: -190px;
  }
}
/* Color styles */
.tag {
  display: flex;
  padding: 4px 6px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background-color: #EFEFF2;
  width: fit-content;
}

.tag .tag__content {
  color: rgb(20, 46, 58);
  font-family: Nippo;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/* Color styles */
.clients_wrapper {
  display: flex;
  flex-direction: column;
  gap: 0;
  margin-top: 96px;
}

.clients_wrapper .clients_wrapper__logotypes {
  display: flex;
  flex-direction: row;
  gap: 0;
}

.header {
  display: flex;
  flex-direction: row;
  gap: 0;
  height: 100%;
}

.header__left {
  font-family: Nippo;
  background: url("/src/resources/HERO_PATTERN.png") rgb(34, 30, 31);
  flex-shrink: 0;
  flex-basis: 40%;
  padding: 36px 32px;
  min-width: 300px;
}

.header__left .header__title {
  color: rgb(255, 255, 255);
  font-size: 42px;
  font-weight: 500;
}

.header__left .header__subtitle {
  color: rgb(210, 203, 192);
  font-size: 18px;
  font-weight: 400;
}

.header__middle {
  height: 100%;
}

.header__middle img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.header__right {
  height: 100%;
  display: flex;
  align-items: end;
  background-color: rgb(246, 247, 250);
}

.client-card {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 190px;
  padding: 48px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(230, 233, 235);
}

.client-card img {
  opacity: 0.5;
  filter: saturate(0);
  width: 100%;
  object-fit: scale-down;
  transition: all 0.66s cubic-bezier(0.22, 1, 0.36, 1);
}

.client-card:last-of-type {
  border-right: 1px solid rgb(230, 233, 235);
}

.client-card:hover img {
  opacity: 1;
  filter: saturate(1);
  transition: all 0.66s cubic-bezier(0.22, 1, 0.36, 1);
}

@media screen and (max-width: 920px) {
  .clients_wrapper {
    margin-top: 48px;
  }
  .clients_wrapper .header {
    flex-direction: column;
  }
  .clients_wrapper .header .header__left {
    width: 100%;
    box-sizing: border-box;
  }
  .clients_wrapper .header .header__right {
    width: 100%;
  }
  .clients_wrapper .header .header__right .button {
    width: 100%;
  }
  .clients_wrapper .clients_wrapper__logotypes {
    flex-wrap: wrap;
    justify-content: center;
  }
  .clients_wrapper .header__middle {
    display: none;
  }
  .clients_wrapper .client-card {
    width: 50%;
  }
}
.section_container {
  grid-column: 1/span 12;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  max-width: 1440px;
}

/* Color styles */
.process_wrapper {
  background-color: rgb(246, 247, 250);
  position: relative;
  width: 100%;
}

.process_wrapper .proccess_container--right {
  position: relative;
  box-sizing: border-box;
  display: flex;
  max-width: 640px;
  padding: 80px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.process_wrapper .proccess_container--right .tag {
  background-color: #e0e0e0;
}

.process_wrapper h1 {
  color: rgb(34, 30, 31);
  font-family: Nippo;
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 24px;
  z-index: 2;
  position: relative;
}

.process_wrapper .text {
  color: rgb(34, 30, 31);
  font-size: 20px;
}

.proccess_container--left {
  width: fit-content;
  display: flex;
  flex-shrink: 1;
  margin-bottom: 64px;
  transition: all 0.66s cubic-bezier(0.22, 1, 0.36, 1);
}

.proccess_container--left img {
  width: 100%;
  object-fit: cover;
}

.proccess_container {
  display: flex;
  flex-direction: row;
}

h1::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  background: url("/src/resources/SpeedArrows.png");
  height: 147px;
  width: 305px;
  opacity: 0.05;
  z-index: 1;
}

.proccess_container--right::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgb(20, 46, 58);
  height: 64px;
  width: 427px;
  z-index: 2;
  transition: all 0.66s cubic-bezier(0.22, 1, 0.36, 1);
}

.proccess_container--right:hover::after {
  width: 100%;
  transition: all 0.66s cubic-bezier(0.22, 1, 0.36, 1);
}

@media screen and (max-width: 1064px) {
  .proccess_container {
    flex-direction: column-reverse;
  }
  .process_wrapper .proccess_container--left,
  .process_wrapper .proccess_container--right {
    max-width: unset;
    width: 100%;
  }
  .process_wrapper .proccess_container--right {
    padding: 48px;
    padding-bottom: 96px;
  }
  .proccess_container--right::after {
    width: 75%;
    height: 32px;
    transition: all 0.66s cubic-bezier(0.22, 1, 0.36, 1);
  }
}
/* Color styles */
.plain-card {
  box-sizing: border-box;
  font-family: Nippo;
  position: relative;
  display: flex;
  max-width: 426.5px;
  width: 100%;
  height: 356px;
  padding: 64px 32px;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid rgb(230, 233, 235);
  background: rgb(255, 255, 255);
  gap: 32px;
  transition: all 0.66s cubic-bezier(0.22, 1, 0.36, 1);
}

.plain-card .plain-card__ilustration {
  width: 100px;
  height: 100px;
  pointer-events: none;
}

.plain-card .plain-card__title {
  color: rgb(20, 46, 58);
  font-family: Nippo;
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
}

.plain-card .plain-card__description {
  color: rgb(98, 95, 96);
  font-family: Nippo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  transition: all 0.66s cubic-bezier(0.22, 1, 0.36, 1);
}

.plain-card .plain-card__content {
  display: flex;
  flex-direction: column;
  gap: 32px;
  z-index: 4;
}

.plain-card#link {
  cursor: pointer;
}

.plain-card:hover {
  box-shadow: 0px 0px 0px 16px rgba(0, 0, 0, 0.03) inset;
  background-image: url("/src/resources/SpeedArrowsOpacity.png");
  transition: all 0.66s cubic-bezier(0.22, 1, 0.36, 1);
}

.plain-card:hover .plain-card__description {
  color: rgb(34, 30, 31);
  transition: all 0.66s cubic-bezier(0.22, 1, 0.36, 1);
}

.plain-card--modern {
  background: rgb(246, 247, 250);
  position: relative;
  justify-content: center;
  overflow: hidden;
}

.plain-card--modern .tag {
  position: absolute;
  top: 18px;
  left: 18px;
}

.plain-card--modern .plain-card__ilustration {
  position: absolute;
  right: -356px;
  top: 36px;
  width: unset;
  height: unset;
  transition: all 0.66s cubic-bezier(0.22, 1, 0.36, 1);
}

.plain-card--modern::after {
  content: "";
  position: absolute;
  bottom: 75px;
  left: 0;
  background: url("/src/resources/SpeedArrows.png");
  width: 300px;
  height: 174px;
  opacity: 0.05;
  transition: all 0.66s cubic-bezier(0.22, 1, 0.36, 1);
}

.plain-card__content .plain-card--modern__title {
  color: rgb(20, 46, 58);
  font-family: Outfit;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  max-width: 220px;
}

.plain-card--modern:hover::after {
  content: "";
  position: absolute;
  bottom: 75px;
  left: 80px;
  background: url("/src/resources/SpeedArrows.png");
  width: 300px;
  height: 174px;
  opacity: 0.08;
  transition: all 0.66s cubic-bezier(0.22, 1, 0.36, 1);
}

.plain-card--modern:hover .plain-card__ilustration {
  transform: scale(1.05);
  transition: all 0.66s cubic-bezier(0.22, 1, 0.36, 1);
}

.cards_wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.list_wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.list_wrapper h3 {
  color: var(--Black, #221E1F);
  font-family: Nippo;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

@media (max-width: 997px) {
  #empty-card {
    display: none;
  }
}
/* Color styles */
.list-item {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.list-item-label {
  color: #625F60;
  font-family: Nippo;
  font-size: 16px;
  font-style: normal;
  font-weight: 378;
  line-height: normal;
  text-transform: uppercase;
}

.list-item-label:hover {
  color: rgb(34, 30, 31);
  text-decoration-line: underline;
  transition: all 0.66s cubic-bezier(0.22, 1, 0.36, 1);
}

.partners_wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.partners_wrapper .photo_header_container {
  position: relative;
  width: 100%;
}

.partners_wrapper .photo_header_container img {
  width: 100%;
  height: 659px;
  object-fit: cover;
}

.partners_wrapper .header {
  position: absolute;
  bottom: 0;
  height: 130px;
}

.partners_wrapper .header__left {
  height: 130px;
  padding-block: 15px;
  box-sizing: border-box;
  flex-shrink: 0;
  flex-basis: 60%;
  min-width: 480px;
}

.partners_wrapper .header__right {
  width: 100%;
  height: 100%;
  background: rgba(45, 45, 45, 0.17);
  backdrop-filter: blur(4.5px);
  flex-shrink: 1;
}

.partners-cards_wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media screen and (max-width: 998px) {
  #empty {
    display: none;
  }
}
@media screen and (max-width: 620px) {
  .partners_wrapper .header {
    bottom: -5px;
  }
  .partners_wrapper .header__left {
    flex-grow: 1;
    width: 100%;
    min-width: unset;
    margin-bottom: 20px;
  }
  .partners_wrapper .header__right {
    display: none;
  }
  .partners_wrapper .header__left {
    max-height: unset;
    height: fit-content;
  }
}
/* Color styles */
.plain-card, .partner-card {
  box-sizing: border-box;
  font-family: Nippo;
  position: relative;
  display: flex;
  max-width: 426.5px;
  width: 100%;
  height: 356px;
  padding: 64px 32px;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid rgb(230, 233, 235);
  background: rgb(255, 255, 255);
  gap: 32px;
  transition: all 0.66s cubic-bezier(0.22, 1, 0.36, 1);
}

.plain-card .plain-card__ilustration, .partner-card .plain-card__ilustration {
  width: 100px;
  height: 100px;
  pointer-events: none;
}

.plain-card .plain-card__title, .partner-card .plain-card__title {
  color: rgb(20, 46, 58);
  font-family: Nippo;
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
}

.plain-card .plain-card__description, .partner-card .plain-card__description {
  color: rgb(98, 95, 96);
  font-family: Nippo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  transition: all 0.66s cubic-bezier(0.22, 1, 0.36, 1);
}

.plain-card .plain-card__content, .partner-card .plain-card__content {
  display: flex;
  flex-direction: column;
  gap: 32px;
  z-index: 4;
}

.plain-card#link, #link.partner-card {
  cursor: pointer;
}

.plain-card:hover, .partner-card:hover {
  box-shadow: 0px 0px 0px 16px rgba(0, 0, 0, 0.03) inset;
  background-image: url("/src/resources/SpeedArrowsOpacity.png");
  transition: all 0.66s cubic-bezier(0.22, 1, 0.36, 1);
}

.plain-card:hover .plain-card__description, .partner-card:hover .plain-card__description {
  color: rgb(34, 30, 31);
  transition: all 0.66s cubic-bezier(0.22, 1, 0.36, 1);
}

.plain-card--modern {
  background: rgb(246, 247, 250);
  position: relative;
  justify-content: center;
  overflow: hidden;
}

.plain-card--modern .tag {
  position: absolute;
  top: 18px;
  left: 18px;
}

.plain-card--modern .plain-card__ilustration {
  position: absolute;
  right: -356px;
  top: 36px;
  width: unset;
  height: unset;
  transition: all 0.66s cubic-bezier(0.22, 1, 0.36, 1);
}

.plain-card--modern::after {
  content: "";
  position: absolute;
  bottom: 75px;
  left: 0;
  background: url("/src/resources/SpeedArrows.png");
  width: 300px;
  height: 174px;
  opacity: 0.05;
  transition: all 0.66s cubic-bezier(0.22, 1, 0.36, 1);
}

.plain-card__content .plain-card--modern__title {
  color: rgb(20, 46, 58);
  font-family: Outfit;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  max-width: 220px;
}

.plain-card--modern:hover::after {
  content: "";
  position: absolute;
  bottom: 75px;
  left: 80px;
  background: url("/src/resources/SpeedArrows.png");
  width: 300px;
  height: 174px;
  opacity: 0.08;
  transition: all 0.66s cubic-bezier(0.22, 1, 0.36, 1);
}

.plain-card--modern:hover .plain-card__ilustration {
  transform: scale(1.05);
  transition: all 0.66s cubic-bezier(0.22, 1, 0.36, 1);
}

.partner-card {
  position: relative;
  padding: 32px;
  gap: 8px;
}

.partner-card .button {
  position: absolute;
  bottom: 0;
  right: 0;
}

.partner-card .partner-card__description {
  color: #625F60;
  font-family: Nippo;
  font-size: 16px;
  font-style: normal;
  font-weight: 378;
  line-height: normal;
  text-transform: uppercase;
}

.partner-card .partner-card__content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.partner-card--empty {
  background-color: rgb(246, 247, 250);
  background-image: url("/src/resources/SpeedArrowsOpacity.png");
}

@media screen and (max-width: 420px) {
  .partner-card__content {
    width: 90%;
  }
}
/* Color styles */
.footer {
  width: 100%;
  background: url("/src/resources/HERO_PATTERN.png") rgb(34, 30, 31);
}

.footer__container {
  box-sizing: border-box;
  margin: auto;
  max-width: 1440px;
  min-height: 577px;
  height: auto;
  flex-direction: column;
  align-items: flex-start;
  gap: 36px;
}

.footer__copyritgh-section {
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("/src/resources/SpeedArrows.png") rgb(34, 30, 31);
  background-size: 25px;
  background-blend-mode: color-dodge;
}

.footer__copyrights {
  color: rgb(255, 255, 255);
  font-family: Nippo;
  font-size: 12px;
  font-style: normal;
  font-weight: 378;
  line-height: normal;
  opacity: 0.25;
}

.footer__title {
  color: #FFFFFF;
  font-family: Nippo;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  text-align: right;
}

.footer__top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 36px;
  padding-inline: 64px;
  padding-top: 64px;
  flex-wrap: wrap;
  gap: 32px;
}

.footer__top img {
  mix-blend-mode: lighten;
}

.footer__bottom {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 64px;
}

.footer__card {
  box-sizing: border-box;
  display: flex;
  max-width: 431px;
  width: 100%;
  height: 208px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border: 1px solid #434445;
  background: rgb(34, 30, 31);
  overflow: hidden;
}

.footer__card.footer__card--highlight {
  background: rgb(210, 203, 192);
  position: relative;
}

.footer__card.footer__card--highlight .footer__card-text--bold {
  color: rgb(34, 30, 31);
  font-size: 36px;
  font-weight: 500;
}

.footer__card.footer__card--highlight .footer__hr {
  background: rgb(20, 46, 58);
}

.footer__card .footer__card-content {
  display: flex;
  flex-direction: column;
}

.footer__card .footer__card-text--bold {
  color: rgb(255, 255, 255);
  font-family: Nippo;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}

.footer__card .footer__card-text--light {
  color: rgb(255, 255, 255);
  font-family: Nippo;
  font-size: 24px;
  font-style: normal;
  font-weight: 200;
}

.footer__card .footer__card-text--link {
  color: rgb(210, 203, 192);
  font-family: Nippo;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
}

.footer__card .footer__link {
  width: 150px;
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.footer__card .footer__card-text--link:hover {
  text-decoration: underline;
}

.footer__card .footer__hr {
  width: 150px;
  height: 2px;
  background: rgb(210, 203, 192);
  margin-top: 12px;
  margin-bottom: 4px;
}

.footer__card--highlight::after {
  content: "";
  position: absolute;
  bottom: -110px;
  left: 236px;
  background: url("/src/resources/LOGO.png");
  width: 281px;
  height: 293px;
  mix-blend-mode: darken;
  opacity: 0.2;
  transform: rotate(344deg);
}

@media screen and (max-width: 865px) {
  .footer__title {
    text-align: left;
  }
}
@media screen and (max-width: 420px) {
  .footer__top {
    padding: 24px;
  }
}
/* Color styles */
.contact-section__wrapper {
  font-family: Nippo;
  border-radius: 32px;
  border: 1px solid rgb(34, 30, 31);
  background: rgb(255, 255, 255);
  display: flex;
  padding: 48px;
  align-items: flex-start;
  min-height: 1024px;
  width: fit-content;
}

.contact-section {
  display: flex;
  height: 100%;
  width: fit-content;
  box-shadow: 0px 4.26158px 2.21381px 0px rgba(0, 0, 0, 0.05), 0px 10.24116px 5.32008px 0px rgba(0, 0, 0, 0.08), 0px 19.28319px 10.01724px 0px rgba(0, 0, 0, 0.09), 0px 34.39791px 17.86905px 0px rgba(0, 0, 0, 0.11), 0px 64.33752px 33.42209px 0px rgba(0, 0, 0, 0.14), 0px 154px 80px 0px rgba(0, 0, 0, 0.19);
}

.contact-form {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  width: fit-content;
  padding: 64px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(230, 233, 235);
}

.contact-form .contact-form__title {
  color: rgb(34, 30, 31);
  text-align: left;
  font-family: Nippo;
  font-size: 52px;
  font-style: normal;
  font-weight: 700;
  line-height: 105%;
  margin-bottom: 10px;
}

.contact-form .contact-form__subtitle {
  color: rgb(20, 46, 58);
  font-family: Nippo;
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
  display: flex;
  max-width: 515px;
}

.contact-form .toggle-buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 32px;
  margin-top: 32px;
}

.contact-form .button {
  width: 100%;
}

.contact-section__arrow {
  mix-blend-mode: darken;
  position: absolute;
  top: 136px;
  right: 20px;
}

.contact-section__left {
  display: flex;
  flex-direction: column;
  border-right: 1px solid rgb(34, 30, 31);
  flex-basis: 60%;
}

.contact-section__heading {
  color: rgb(34, 30, 31);
  font-family: Nippo;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.contact-section__ilustration {
  background-color: rgb(20, 46, 58);
  display: flex;
  justify-content: center;
  height: 464px;
  flex-shrink: 0;
  position: relative;
  display: flex;
}

.contact-section__ilustration svg {
  position: absolute;
  object-fit: cover;
  width: 150%;
  mix-blend-mode: luminosity;
  transform: rotateZ(-8deg);
  z-index: 2;
}

.contact-form__inputfields {
  margin: 48px 0;
  display: flex;
  flex-direction: column;
  gap: 48px;
}

@media (min-width: 1300px) {
  #contact_section.section__container {
    padding: 64px;
  }
}
@media (max-width: 1024px) {
  .contact-section__wrapper {
    padding: 0;
    border-radius: 0;
  }
  .contact-section {
    flex-direction: column;
    border-radius: 30px;
    border: none;
    width: 85%;
  }
  form {
    width: 100%;
  }
  .contact-form {
    border-radius: 0;
    padding: 32px;
    width: 100%;
  }
  .contact-form .contact-form__title {
    font-size: 36px;
  }
  .contact-form .contact-form__subtitle {
    font-size: 16px;
  }
}
/* Color styles */
.inputfield {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.inputfield textarea {
  resize: none;
  height: 120px;
}

.inputfield ::-webkit-scrollbar {
  width: 6px;
}

.inputfield ::-webkit-scrollbar-thumb {
  background-color: rgb(20, 46, 58);
  border-radius: 0px;
}

.inputfield ::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
}

.inputfield ::-webkit-scrollbar-track:hover {
  background-color: rgb(246, 247, 250);
}

.input {
  display: flex;
  background: none;
  outline: none;
  border: none;
  padding: 12px 24px;
  background-color: rgba(0, 0, 0, 0.082);
  color: rgb(20, 46, 58);
  font-family: Nippo;
  font-size: 18px;
  font-weight: 400;
  border-bottom: 3px solid rgb(20, 46, 58);
}

.input::selection {
  background-color: rgb(20, 46, 58);
  color: rgb(255, 255, 255);
}

.inputfield__label {
  color: rgb(20, 46, 58);
  font-family: Nippo;
  font-size: 16px;
  font-weight: 700;
  opacity: 0.8;
}

.input:hover,
.input:focus {
  outline: 1px solid rgb(20, 46, 58);
  background: rgb(255, 255, 255);
  color: rgb(20, 46, 58);
  cursor: text;
  border-bottom: 3px solid rgb(20, 46, 58);
  transition: all 0.66s cubic-bezier(0.22, 1, 0.36, 1);
}

.input::placeholder {
  font-weight: 400;
  opacity: 0.8;
}

/* Color styles */
.about_us__wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  width: 100%;
  grid-column: 1/span 12;
}

.about_us__wrapper .photo_header_container {
  position: relative;
  width: 100%;
  height: 659px;
}

.about_us__wrapper .photo_header_container img {
  width: 100%;
  height: 659px;
  object-fit: cover;
}

.about_us__wrapper .header {
  position: absolute;
  bottom: 0px;
  height: fit-content;
}

.about_us__wrapper .header__left {
  height: 130px;
  padding-block: 15px;
  box-sizing: border-box;
  flex-shrink: 0;
  flex-basis: 60%;
  flex-grow: 1;
}

.about_us__wrapper .header__right {
  width: 100%;
  height: 100%;
  background: rgba(45, 45, 45, 0.17);
  backdrop-filter: blur(4.5px);
  flex-shrink: 1;
}

.person__photo {
  box-shadow: 0px 4px 0px 13px rgba(0, 0, 0, 0.08) inset;
}

.about-us__cards {
  display: flex;
  flex-direction: column;
  margin-bottom: 128px;
}

.about-us__cards .about-us__header {
  display: flex;
  flex-direction: row;
}

.about-us__cards .person-cards__wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.about-us__cards .person-cards__wrapper .plain-card {
  padding: 32px 32px;
}

.about-us__cards .plain-card {
  flex-shrink: 0;
  height: fit-content;
}

.about-us__cards .plain-card .person__data {
  padding-top: 28px;
  display: flex;
  flex-direction: column;
}

.about-us__cards .plain-card .person__data .person__name {
  color: rgb(34, 30, 31);
  font-family: Nippo;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

.about-us__cards .plain-card .person__data .person__email {
  color: #625F60;
  font-family: Nippo;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: uppercase;
}

.about-us__cards .plain-card .contact__item-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 24px;
}

.about-us__cards .plain-card .contact__item {
  display: flex;
  flex-direction: row;
  gap: 12px;
  font-size: 18px;
}

.about-us__cards .plain-card .contact__item .contact__item-label {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  color: #625F60;
  font-family: Nippo;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: uppercase;
}

.about-us__cards .plain-card .contact__item .contact__item-label--text {
  width: 64px;
}

.about-us__cards .shape__decoration {
  flex-grow: 0;
  min-width: 0;
  height: auto;
  object-fit: cover;
}

.about-us__cards .plain-card .plain-card__description {
  color: rgb(34, 30, 31);
  font-family: Nippo;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: uppercase;
}

@media screen and (max-width: 980px) {
  .person-cards__wrapper .plain-card {
    padding-inline: 24px;
    width: fit-content;
  }
}
@media screen and (max-width: 820px) {
  .about_us__wrapper .person-cards__wrapper {
    justify-content: center;
  }
  .about_us__wrapper .about-us__header .plain-card {
    width: 100%;
    max-width: unset;
  }
  .about_us__wrapper .about-us__header img {
    display: none;
  }
  .about_us__wrapper .header__left {
    flex-grow: 1;
    width: 100%;
    min-width: unset;
  }
  .about_us__wrapper .header__right {
    display: none;
  }
  .about_us__wrapper .header__left {
    max-height: unset;
    height: fit-content;
  }
}
/* Color styles */
img {
  pointer-events: none;
  user-select: none;
}

a {
  text-decoration: none;
}

* {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: all 0.66s cubic-bezier(0.22, 1, 0.36, 1);
}

body {
  font-family: "Nippo-Regular", sans-serif;
  background: url("/src/resources/SpeedArrowsOpacity.png"), #FFF;
  background-size: 80px;
}

.grid-fill {
  max-width: 1440px;
  grid-column: 1/span 12;
}

.text {
  font-family: Nippo;
}

.text--highlight {
  color: rgb(210, 203, 192);
}

.text--regular {
  font-weight: 400;
}

.text--light {
  font-weight: 300;
}

@media screen and (max-width: 530px) {
  .app {
    padding-inline: 24px;
  }
}
@media screen and (max-width: 420px) {
  .app {
    padding: 0;
  }
}
/**
 * @license
 *
 * Font Family: Nippo
 * Designed by: Manushi Parikh
 * URL: https://www.fontshare.com/fonts/nippo
 * © 2022 Indian Type Foundry
 *
 * Font Styles:
 * Nippo Variable(Variable font)
 * Nippo Extralight
 * Nippo Light
 * Nippo Regular
 * Nippo Medium
 * Nippo Bold
 *
*/
/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 700.0;
*
* available axes:

* 'wght' (range from 200.0 to 700.0)

*/
@font-face {
  font-family: "Nippo-Variable";
  src: url("/src/fonts/Nippo-Variable.woff2") format("woff2"), url("/src/fonts/Nippo-Variable.woff") format("woff"), url("/src/fonts/Nippo-Variable.ttf") format("truetype");
  font-weight: 200 700;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Nippo-Extralight";
  src: url("/src/fonts/Nippo-Extralight.woff2") format("woff2"), url("/src/fonts/Nippo-Extralight.woff") format("woff"), url("/src/fonts/Nippo-Extralight.ttf") format("truetype");
  font-weight: 200;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Nippo-Light";
  src: url("/src/fonts/Nippo-Light.woff2") format("woff2"), url("/src/fonts/Nippo-Light.woff") format("woff"), url("/src/fonts/Nippo-Light.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Nippo-Regular";
  src: url("/src/fonts/Nippo-Regular.woff2") format("woff2"), url("/src/fonts/Nippo-Regular.woff") format("woff"), url("/src/fonts/Nippo-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Nippo-Medium";
  src: url("/src/fonts/Nippo-Medium.woff2") format("woff2"), url("/src/fonts/Nippo-Medium.woff") format("woff"), url("/src/fonts/Nippo-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Nippo-Bold";
  src: url("/src/fonts/Nippo-Bold.woff2") format("woff2"), url("/src/fonts/Nippo-Bold.woff") format("woff"), url("/src/fonts/Nippo-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
